<template>
  <div id="preview">
    <div class="inner">
      <iframe style="width: 100%; height: 1120px;" src="https://ditto.fm/big-news"></iframe>
      <!-- <div class="image fit">
        <img src="images/big-news.jpg" alt="Big News by Shutterwax" />
      </div> -->
      <div class="content">
        <header>
          <h2>Big News</h2>
        </header>
        <iframe style="border: 0; width: 100%; height: 42px;" src="https://bandcamp.com/EmbeddedPlayer/track=88128726/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="http://shutterwax.com/track/big-news">Big News by Shutterwax</a></iframe>
        <p>A song to help you understand the world of news</p>
      </div>
    </div>
    <router-link :to="{ name: 'Jeff', params: {} }" class="nav previous">
      <span class="fa fa-chevron-left"></span>
    </router-link>
    <router-link :to="{ name: 'Jessica', params: {} }" class="nav next">
      <span class="fa fa-chevron-right"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BigNewsPage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
